<template>
    <div>
        <Navbar v-if="$store.state.user.cliente" page="Seu perfil" />
        <Navbar v-else page="Pessoas na empresa" link="/cadastros/pessoas" nameLink="Pessoas" video="https://youtu.be/ZA7dytkGVGA"/>
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="flex mb-5">
                <h1 class="text-2xl mt-2 ml-2">
                    {{ $store.state.user.cliente ? 'Seu perfil' : 'Pessoas na empresa' }}
                </h1>
            </div>
            
            <form action="#" method="POST" class="text-sm">
                <div class="grid grid-cols-12 gap-4 mb-4">
                    <div class="col-span-12 md:col-span-4">
                        <label for="nome">Nome Completo *</label>
                        <input v-model="form.nome" 
                        type="text" 
                        name="nome" 
                        id="nome" 
                        class="focus:ring-yellow-400 focus:border-yellow-400 w-full border-gray-300 text-sm p-1">
                    </div> 
                    <div class="col-span-12 md:col-span-4">
                        <label for="email" class="block ">Email *</label>
                        <input v-model="form.email" type="email" name="email" id="email" class="focus:ring-yellow-400 focus:border-yellow-400 w-full border-gray-300 text-sm p-1">
                    </div> 
                    <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-2">
                        <label for="receberEmail" class="block ">Receber e-mail</label>
                        <select required v-model="form.receberEmail" name="receberEmail" id="receberEmail" class="w-full border border-gray-300 bg-white  focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm p-1.5">
                            <option :value="true">Sim</option>
                            <option :value="true">Não</option>
                        </select>
                    </div>

                    <div class="col-span-12 md:col-span-2">
                        <label for="password" class="block ">Senha</label>
                        <input v-model="form.password" type="password" name="password" id="password" autocomplete="new-password" class="focus:ring-yellow-400 focus:border-yellow-400 block w-full border-gray-300 text-sm p-1">
                    </div>
                </div>
                <div class="grid grid-cols-12 gap-2">
                    
                    <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-4">
                        <div class="flex flex-row">
                            <label for="setor">Setor / Departamento</label>
                            <svg fill="#059669" viewBox="0 0 256 256" @click="novoItem('setores')" xmlns="http://www.w3.org/2000/svg" class="ml-1 h-5 inline text-green-600">
                                <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                            </svg>
                        </div>
                        <select required v-model="form.setor" name="setor" id="setor" class="w-full border border-gray-300 bg-white focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm p-1.5">
                            <option v-for="setor in setores" :key="setor._id" :value="setor._id">{{ setor.nome }}</option>
                        </select>
                    </div>

                    <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-3">
                        <div class="flex flex-row">
                            <label for="funcao">Cargo / Função</label>
                            <svg fill="#059669" viewBox="0 0 256 256" @click="novoItem('funcoes')" xmlns="http://www.w3.org/2000/svg" class="ml-1 h-5 inline text-green-600">
                                <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                            </svg>
                        </div>
                        <select required v-model="form.funcao" name="funcao" id="funcao" class="w-full border border-gray-300 bg-white focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm p-1.5">
                            <option v-for="funcao in funcoes" :key="funcao._id" :value="funcao._id">{{ funcao.nome }}</option>
                        </select>
                    </div> 

                    <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-5">
                        <div class="flex flex-row">
                            <label for="organizacao">Organização</label>
                            <svg fill="#059669" viewBox="0 0 256 256" @click="novoItem('organizacoes')" xmlns="http://www.w3.org/2000/svg" class="ml-1 h-5 inline text-green-600">
                                <path d="M128,28A100,100,0,1,0,228,128,100.11,100.11,0,0,0,128,28Zm0,192a92,92,0,1,1,92-92A92.1,92.1,0,0,1,128,220Zm44-92a4,4,0,0,1-4,4H132v36a4,4,0,0,1-8,0V132H88a4,4,0,0,1,0-8h36V88a4,4,0,0,1,8,0v36h36A4,4,0,0,1,172,128Z"></path>
                            </svg>
                        </div>
                        <select required v-model="form.organizacao" name="organizacao" id="organizacao" class="w-full border border-gray-300 bg-white focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm p-1.5">
                            <option v-for="organizacao in organizacoes" :key="organizacao._id" :value="organizacao._id">{{ organizacao.nome }}</option>
                        </select>
                    </div> 

                    <div v-if="!$store.state.user.cliente" class="col-span-12 md:col-span-5">
                        <label for="ativo" class="block ">Situação atual</label>
                        <select required v-model="form.ativo" name="ativo" id="ativo" class="w-full border border-gray-300 bg-white focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm p-1.5">
                            <option :value="true">Ativo</option>
                            <option :value="false">Desligado (inativo)</option>
                        </select>
                    </div> 

                    <div v-if="!$store.state.user.cliente && !form.ativo" class="col-span-12 md:col-span-5">
                        <label for="dataDesligamento">Data de desligamento</label>
                        <input type="date" v-model="form.dataDesligamento" id="dataDesligamento" class="w-full border border-gray-300 bg-white focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 text-sm p-1">
                    </div> 
                    
                </div>

                
                <div class="flex flex-row gap-2 mt-10 justify-end">
                    <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm  rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                        Voltar
                    </button>
                    <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm  rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                        Salvar
                    </button>
                </div>
                
            </form>
        </div>


        <modal name="modalNovoItem" :adaptive="true" :height="'auto'" :clickToClose="true">
            <div class="p-4">
                <div v-if="tipo === 'organizacoes'">
                   Cadastrar uma nova Organização
                </div>
                <div v-if="tipo === 'setores'">
                   Cadastrar um novo Setor / Departamento
                </div>
                <div v-if="tipo === 'funcoes'">
                   Cadastrar um novo Cargo / Função
                </div>
                <div v-if="tipo === 'organizacoes' || tipo === 'funcoes' || tipo === 'setores' ">
                    <hr>
                    <div class="mt-4">
                        <label for="formNovoItem_nome">Nome</label>
                        <input v-model="formNovoItem.nome" 
                        type="text" 
                        name="formNovoItem_nome" 
                        id="formNovoItem_nome" 
                        class="focus:ring-yellow-400 focus:border-yellow-400 w-full border-gray-300 text-sm p-1">
                    </div> 
                </div>

                <div>
                    <button @click="cadastrarNovoItem" class="bg-green-500 text-white p-1 mt-2" type="button">Cadastrar</button>
                </div>
            </div>
        </modal>


    </div>



</template>

<script>
import moment from 'moment'
export default {

    data() {
        return {
            route: 'pessoas',
            alterarPassword: this.$route.params.id ? false : true,
            form: {
                nome: '',
                setor: null,
                email: '',
                telefone: '',
                password: '',
                funcao: null,
                receberEmail: true,
                timePrivacidade: false,
                foto: null
            },
            dropzoneOptions: this.$http.getDropzoneConfig(
                `v1/upload`,
                'post',
            {
                maxFiles: 1,
                addRemoveLinks: true,
                capture: true,
                dictDefaultMessage: 'Clique ou arraste aqui',
                dictRemoveFile: 'Remover'
            }),
            setores: [],
            funcoes: [],
            organizacoes: [],

            formNovoItem: {
                nome: ''
            },
            tipo: ''
        }
    },
    methods: {
        async cadastrarNovoItem() {
            const req = await this.$http.post(`/v1/${this.tipo}`, this.formNovoItem)

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");

                if (this.tipo === 'organizacoes') {
                    this.organizacoes = await this.getOrganizacoes();
                }

                if (this.tipo === 'funcoes') {
                    this.funcoes = await this.getFuncoes();
                }

                if (this.tipo === 'setores') {
                    this.setores = await this.getSetores();
                }

                this.formNovoItem.nome = '';

                this.$modal.hide('modalNovoItem');
            } else {
                this.$vToastify.error(req.data.err);
            }

        },
        novoItem(t) {
            this.tipo = t
            this.$modal.show("modalNovoItem")
        },  
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        },
        updateFoto(file, response) {
            this.form.foto = response.file;
        },


        async getOrganizacoes() {
            const req = await this.$http.post(`/v1/organizacoes/list`, {all: true});
            return req.data.data
        },

        async getFuncoes() {
            const req = await this.$http.post(`/v1/funcoes/list`, {all: true});
            return req.data.data
        },

        async getSetores() {
            const req = await this.$http.post(`/v1/setores/list`, {all: true});
            return req.data.data
        },
    },
    async beforeMount() {
        const id = this.$route.params.id;

        this.setores = await this.getSetores();
        this.funcoes = await this.getFuncoes();
        this.organizacoes = await this.getOrganizacoes();

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;

            if (this.form.dataDesligamento) {
                this.form.dataDesligamento = moment(this.form.dataDesligamento).format("YYYY-MM-DD")
            }
        }
    },
}
</script>